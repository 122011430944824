<template>
   <section>
      <div class="">
         <b-modal
            id="modal-newsletters"
            ok-only
            ok-title="Envoyer"
            centered
            title="Newsletters"
            @ok="sendProspecting"
         >
            <div class="row mb-1">
               <div
                  v-if="selected.length === 0"
                  class="col-12 p-1 rounded alert-danger text-center"
               >
                  <span class="p-0 pl-1 font-weight-bold text-danger h7"
                     >Vous devez sélectionner un ou plusieurs email.</span
                  >
                  <span class="p-0 pl-1 font-weight-bold text-danger h6"
                     >⚠️</span
                  >
               </div>
            </div>

            <div class="row">
               <div class="col-lg-12">
                  <b-form-group label-for="vi-first-name">
                     <label for="">Subject</label>
                     <b-input-group class="input-group-merge">
                        <b-form-input
                           id="vi-first-name"
                           v-model="input.model.subject"
                           placeholder="Entrez le suject de la prospections !"
                        />
                     </b-input-group>
                     <!-- <small v-if="valideName" class="text-danger"
                  >Le nom est obligatoire</small
                > -->
                  </b-form-group>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-12">
                  <label for="">Message</label>
                  <b-form-textarea
                     id="textarea"
                     v-model="input.model.customMessage"
                     placeholder="Accompagner le catalogue d'un message ?"
                     rows="5"
                     max-rows="6"
                  >
                  </b-form-textarea>
               </div>
            </div>

            <template #modal-footer>
               <b-button
                  :disabled="
                     state.loading === true || selected.length === 0
                        ? true
                        : false
                  "
                  variant="primary"
                  @click.stop.prevent="sendProspecting"
               >
                  <span v-if="state.loading === false">Envoyer</span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!-- Tableau pour afficher les utilisateurs-->
         <div class="tableau">
            <b-card no-body class="">
               <b-row class="px-2 py-2">
                  <!-- Per Page -->
                  <b-col
                     cols="12"
                     md="6"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
                  >
                     <label>Entrées</label>
                     <v-select
                        v-model="state.perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="state.perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                     />

                     <b-button variant="primary" v-b-modal.modal-newsletters>
                        <div class="flex gap-2">
                           <feather-icon icon="SendIcon" class="mx-auto" />
                           <span> Envoyer la prospection </span>
                        </div>
                     </b-button>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="4" class="mt-1">
                     <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="input-group-merge">
                           <b-form-input
                              v-model="state.filtreNewsletter"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : Nom, role, contact"
                           />
                        </b-input-group>
                     </div>
                  </b-col>
               </b-row>
               <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="state.perPage"
                  :current-page="state.currentPage"
                  :items="newsletters"
                  :fields="tableColumns"
                  :filter="state.filtreNewsletter"
                  show-empty
                  empty-text=""
                  class="bg-white"
               >
                  <!-- new butt -->
                  <template #head(all)>
                     <b-form-checkbox
                        id="checkbox-1"
                        v-model="status__check"
                        name="checkbox-1"
                        value="accepted"
                        @change="selection_all"
                        unchecked-value="not_accepted"
                     >
                        <span style="font-size: 12px">
                           {{ state.countSelected }}
                        </span>
                     </b-form-checkbox>
                  </template>

                  <template #cell(all)="data">
                     <div class="d-flex">
                        <b-form-checkbox
                        :id="'newsletter_'+data.item.id"
                           v-model="selected"
                           :value="data.item.email"
                           @change="selection"
                           unchecked-value="not_accepted"
                           class="messageCheckbox"
                        >
                        </b-form-checkbox>
                        <span class="">{{ data.item.index }}</span>
                     </div>
                  </template>
               </b-table>

               <!-- Les boutons de pagination -->
               <div class="mx-2 mb-2 pb-1">
                  <b-row>
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                     >
                        <span class="text-muted"></span>
                     </b-col>
                     <!-- Pagination -->
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                     >
                        <b-pagination
                           v-model="state.currentPage"
                           :total-rows="newsletters.length"
                           :per-page="state.perPage"
                           first-number
                           last-number
                           class="mb-0 mt-1 mt-sm-0"
                           prev-class="prev-item"
                           next-class="next-item"
                        >
                           <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                           </template>
                           <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                           </template>
                        </b-pagination>
                     </b-col>
                  </b-row>
               </div>
            </b-card>
         </div>
      </div>
   </section>
</template>

<script>
import {
   BSpinner,
   BRow,
   BCol,
   BModal,
   BTable,
   BPagination,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
   BFormTextarea,
   BBadge,
   VBToggle,
   BCard,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/request';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import { computed, onMounted, reactive, ref } from '@vue/composition-api';
import VueCountryCode from 'vue-country-code-select';
import { root } from 'postcss';

export default {
   components: {
      BSpinner,
      ToastificationContent,
      VueCountryCode,
      flatPickr,
      vSelect,
      VBToggle,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BTable,
      BPagination,
      BCard,
      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BFormTextarea,
      BInputGroupAppend,
      BBadge,

      // validations
      ValidationProvider,
      ValidationObserver,
   },
   directives: {
      Ripple,
      'b-toggle': VBToggle,
   },
   mixins: [heightTransition],
   setup(props, { root }) {
      const selected = ref([]);
      const status__check = ref('not_accepted');
      const state = reactive({
         loading: false,
         currentPage: 1,
         perPage: 30,
         perPageOptions: [30, 50, 100, 200, 500],
         filtreNewsletter: '',
         countSelected: 0,
      });

      const input = reactive({
         model: {
            subject: '',
            email: [],
            customMessage: 'Nouvelle prospection by newsletter',
         },
      });

      const tableColumns = reactive([
         {
            key: 'all',
            label: 'Tout',
            tdClass: 'nameOfTheClass',
         },
         { key: 'email' },
      ]);
      const newsletters = ref([]);

      // OnMounted
      onMounted(() => {
         getNewsletter();
      });

      const getNewsletter = async () => {
         try {
            const { data } = await axios.get(URL.NEWSLETTER_LIST);
            if (data) {
               newsletters.value = data.newsletter;
            }
         } catch (error) {
            console.log(error);
         }
      };

      const xRey = ref([]);

      const selection = (e) => {
         status__check.value = 'not_accepted';
         state.countSelected = selected.value.length;

        input.model.email = e
      };

      const selection_all = () => {
         if (selected.value.length === newsletters.value.length) {
            selected.value = [];
            status__check.value = 'not_accepted';
            state.countSelected = selected.value.length;
            input.model.email = [];
            state.countSelected = 0
         } else {
          input.model.email = [];
            status__check.value = 'accepted';
            for (let i = 0; i < newsletters.value.length; i++) {
               const el = newsletters.value[i];
               input.model.email.push(el.email);
            }

            selected.value = input.model.email;
            state.countSelected = selected.value.length;
         }
      };

      const sendProspecting = async () => {
         try {
            state.loading = true;

            const dataSend = {
               message: input.model.customMessage,
               email: input.model.email,
               subject: input.model.subject,
            };

            const { data } = await axios.post(
               URL.NEWSLETTER_SEND_MESSAGE,
               dataSend
            );
            if (data) {
               console.log(data);
               root.$bvModal.hide('modal-newsletters');
               state.loading = false;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      return {
         state,
         selected,
         status__check,
         tableColumns,
         newsletters,
         selection_all,
         selection,
         sendProspecting,
         input,
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
